import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";

import "./privacypolicy.scss";

function PrivacyPolicyPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-privacypolicy"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo pageTitle="Privacy Policy"
      pageDescription="Read Beiersdorf's privacy policy" />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <Row>
            <Col sm={12}>
              <h1>Privacy Policy</h1>
              <h2>1. Scope and Purpose of Policy.</h2>
              <p>
                You should carefully review this privacy policy before using
                this website. <span className="font-semi-bold">By using this website or taking advantage of any
                service accessed through this website, you signify your assent
                to this privacy policy.</span> If you do not agree to this privacy
                policy, please do not use this website or our services accessed
                through it.
              </p>
              <p>
                Beiersdorf, Inc. (“Beiersdorf”) respects the privacy of its
                users and recognizes the importance of providing a secure
                environment for them. Beiersdorf knows that you care how
                information about you is used and shared. This privacy policy
                describes how Beiersdorf collects, stores, and uses the
                information you provide on this website. You should carefully
                review this privacy policy before using this website or services
                provided through this website. This privacy policy applies only
                to information collected through this website and not to any
                information collected from us or through us offline. This
                privacy policy complies with the California Consumer Privacy Act
                of 2018 (“CCPA”). Any terms defined in the CCPA have the same
                meaning when used in this Privacy Policy.
              </p>
              <p>
                Please note that, when you link to other websites from this
                website, this privacy policy does not apply to, and cannot
                control the activities on, those other sites. Please check those
                websites for their own privacy policies.
              </p>
              <h2>2. Changes to Policy.</h2>
              <p>
                This privacy policy may change from time to time, so please
                review these terms periodically to ensure that you are aware of
                any changes. Your continued use of this website will signify
                your acceptance of any changes.
              </p>
              <h2>3. Collection of Personal Information.</h2>
              <p>
                When you are asked for information while on this website, you
                are sharing that information with Beiersdorf, its parent, and
                its various affiliates and sister companies, unless specifically
                stated otherwise. As a result of that sharing, you may receive
                communications from any of those Beiersdorf affiliates. In
                addition, some services offered by Beiersdorf through this
                website are provided in conjunction with third parties with
                which Beiersdorf has a business relationship. If your Personal
                Information is being collected by a company other than
                Beiersdorf, you will be so notified at the time that the process
                for the collection of that Personal Information is initiated. If
                you do not want to have your Personal Information so shared,
                please do not elect to use the service or facility for which
                that Personal Information is collected.
              </p>
              <p>
                Beiersdorf collects information from you when you register on
                this website, respond to a survey or to a communication such as
                an email, or use other services on this website. Those other
                services may include, for example, the creation of a profile
                page, participation in forums or the provision of facilities to
                permit you to post media content such as images and text.
                Beiersdorf stores that information or may use third parties
                under contract.
              </p>
              <p>
                Specifically, we have collected the following categories of
                personal information from consumers within the last twelve (12)
                months:
              </p>
            </Col>
          </Row>
          <Row className="row-highlight">
            <Col sm={10}>
              <p className="font-semi-bold">Category</p>
            </Col>
            <Col sm={2} className="show-desktop">
              <p className="font-semi-bold">Collected</p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">A. Identifiers.</p>
              <p>
                A real name, alias, postal address, unique personal identifier,
                online identifier, Internet Protocol address, email address,
                account name, Social Security number, driver’s license number,
                passport number, or other similar identifiers.
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                Yes
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">
                B. Personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e)).
              </p>
              <p>
                A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                passport number, driver’s license or state identification card
                number, insurance policy number, education, employment,
                employment history, bank account number, credit card number,
                debit card number, or any other financial information, medical
                information, or health insurance information. Some personal
                information included in this category may overlap with other
                categories.
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                Yes
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">
                C. Protected classification characteristics under California or
                federal law.
              </p>
              <p>
                Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical
                condition, physical or mental disability, sex (including gender,
                gender identity, gender expression, pregnancy or childbirth and
                related medical conditions), sexual orientation, veteran or
                military status, genetic information (including familial genetic
                information).
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                Yes
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">D. Commercial information.</p>
              <p>
                Records of personal property, products or services purchased,
                obtained, or considered, or other purchasing or consuming
                histories or tendencies.
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                Yes
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">E. Biometric information.</p>
              <p>
                Genetic, physiological, biological, behavioral characteristics,
                including DNA that can be used on its own or in combination with
                each other, or other identifying data, to establish an
                individual’s identity. Also includes faceprint, voiceprint,
                keystroke patterns or rhythms, gait patterns or rhythms, and
                sleep, health, or exercise data.
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                No
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">
                F. Internet or other similar network activity.
              </p>
              <p>
                Including, but not limited to browsing history, search history,
                information on a consumer’s interaction with a website,
                application, or advertisement.
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                Yes
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">G. Geolocation data.</p>
              <p>Physical location</p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                Yes
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">H. Sensory data.</p>
              <p>
                Audio, electronic, visual, thermal, olfactory, or similar
                information.
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                No
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">
                I. Professional or employment-related information.
              </p>
              <p>Current or past job history</p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                No
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">
                J. Non-public education information (per the Family Educational
                Rights and Privacy Act (20 U.S.C. Section 1232g, <nobr>34 C.F.R.</nobr> Part
                99)).
              </p>
              <p>
                Education records directly related to a student maintained by an
                educational institution or party acting on its behalf, such as
                grades, transcripts, class lists, student schedules, student
                identification codes, student financial information, or student
                disciplinary records.
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                No
              </p>
            </Col>
          </Row>
          <Row className="row-highlight-details last">
            <Col md={12} lg={10}>
              <p className="font-semi-bold">
                K. Inferences drawn from other personal information.
              </p>
              <p>
                Profile reflecting a person’s preferences, characteristics,
                psychological trends, predispositions, behavior, attitudes,
                intelligence, abilities, and aptitudes.
              </p>
            </Col>
            <Col md={12} lg={2}>
              <p>
                <span className="font-semi-bold show-mobile">
                  Collected:&nbsp;
                </span>
                Yes
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p>
                Beiersdorf obtains the above categories of personal information
                from the following sources:
              </p>
              <ul>
                <li>
                  Directly and/or indirectly from your activity on our website
                </li>
                <li>
                  From third parties that we engage to help provide services to
                  you
                </li>
                <li>Publicly available databases</li>
              </ul>
              <p>
                Beiersdorf uses certain technologies described in Section 7
                below to collect non-identifiable information about you.
              </p>
              <h2>4. Security of Personal Information.</h2>
              <p>
                Beiersdorf has taken reasonable steps to provide that all
                Personal Information collected through this website will remain
                secure. In addition, Beiersdorf will take reasonable steps to
                require that any third parties that may be receiving transfers
                of Personal Information will provide sufficient protection of
                that information.
              </p>
              <p>
                You can help Beiersdorf to protect your Personal Information by:
                (i) using a secure web browser; (ii) not sharing any password or
                log-in data with others; and (iii) changing your password
                frequently.
              </p>
              <h2>5. Use of Personal Information.</h2>
              <p>
                The Personal Information you provide allows Beiersdorf to do the
                following:
              </p>
              <p>
                <span className="font-semi-bold">01:</span> fulfill your orders
                for goods and services;
              </p>
              <p>
                <span className="font-semi-bold">02:</span> alert you of new
                products or services, features, updates and enhancements;
              </p>
              <p>
                <span className="font-semi-bold">03:</span> handle your customer
                service or technical support questions or issues;
              </p>
              <p>
                <span className="font-semi-bold">04:</span> personalize your
                experience while using our services;
              </p>
              <p>
                <span className="font-semi-bold">05:</span> deliver the type of
                content, product offerings and promotions in which you are most
                interested; and{" "}
              </p>
              <p>
                <span className="font-semi-bold">06:</span> notify you of
                upgrade opportunities, contests, promotions, or special events
                and offers.
              </p>
              <p>
                Beiersdorf does not sell, rent, license, trade or exchange
                Personal Information with or to any third parties, except as
                otherwise provided in this privacy policy.
              </p>
              <p>
                Beiersdorf may share your Personal Information among its
                affiliates worldwide. They may use your Personal Information to
                offer products and services to you consistent with the purposes
                identified in this privacy policy.
              </p>
              <p>
                Beiersdorf may use your Personal Information for internal
                marketing, profiling or demographic purposes, so as to adapt
                products and services to better suit your anticipated needs.
              </p>
              <h2>6. Disclosure of Personal Information to Third Parties.</h2>
              <p>
                Beiersdorf may need to disclose items of your Personal
                Information to third-party vendors to fulfill product orders or
                prizes, to process mailings, or to process, analyze, or store
                data on Beiersdorf’s behalf. The categories of third parties we
                disclose your Personal Information to are:
              </p>
              <ul>
                <li>Our parent company</li>
                <li>Our affiliates</li>
                <li>Service providers</li>
                <li>
                  Other third parties you or your agent authorizes for us to
                  provide products or services to you
                </li>
              </ul>
              <p>
                Those third parties are not authorized by Beiersdorf to use or
                disclose your Personal Information for any purpose other than
                for use in connection with the provision of services designated
                by us or the delivery and processing of orders for goods.
                Beiersdorf will take reasonable measures to have those third
                parties maintain the confidentiality, security, and integrity of
                the Personal Information they obtain from it.
              </p>
              <p>
                In the preceding twelve (12) months, we have disclosed the
                following categories of personal information for business
                purposes:
              </p>
              <ul>
                <li>Category A (Identifiers)</li>
                <li>Category B (California Customer Records)</li>
                <li>
                  Category C (Protected classification characteristics under
                  California or federal law)
                </li>
                <li>Category F (Internet or other similar network activity)</li>
                <li>
                  Category I (Professional or employment-related information)
                </li>
              </ul>
              <p>
                Beiersdorf reserves the right to disclose your Personal
                Information as required or permitted by law. This includes, but
                is not limited to, a response to a subpoena, court order or
                other legal process, or in special cases when there is reason to
                believe that disclosing Personal Information is necessary to
                identify, contact, or bring legal action against you if
                Beiersdorf believes that you may be violating your agreements
                with it (including, without limitation, any applicable terms of
                use), or may be causing injury to, or interference with,
                Beiersdorf, any other user of this website or any service, the
                general public or other third parties. You may be asked whether
                you wish to receive information from third parties; upon your
                consent, Beiersdorf or those third parties may use your Personal
                Information to contact you. However, Beiersdorf cannot control
                the activities of third parties to whom it provides data.
                Beiersdorf cannot guarantee that they will adhere to similar
                privacy and security procedures.
              </p>
              <h2>7. Transfers of Personal Information.</h2>
              <p>
                The Personal Information you provide to Beiersdorf may be
                transferred as an asset in connection to a merger or sale
                involving all or part of Beiersdorf or as part of a corporate
                reorganization, stock sale or other change of control. As part
                of its business operations, Beiersdorf may transfer Personal
                Information to recipients in countries that do not provide the
                same level of legal data protection as the country from which
                you access our website or services. By using the Aquaphor and Eucerin HCP
                website or services, you consent to the transfer of Personal
                Information and to the processing by Beiersdorf of that Personal
                Information.
              </p>
              <h2>8. Consumer Rights.</h2>
              <p>
                The CCPA provides California Residents with specific rights
                regarding accessing and deleting personal information. The below
                describes your rights and how you can exercise them, as well as
                Beiersdorf’s responsibility in providing you a response.
              </p>
              <p className="font-semi-bold"><em>Right to Access</em></p>
              <p>
                You have the right to request that we disclose how we collect
                and use your personal information. After we receive and verify
                your request, we will disclose:
              </p>
              <ul>
                <li>
                  Categories of Personal Information Beiersdorf collects about
                  you
                </li>
                <li>
                  Categories of sources for your Personal Information Beiersdorf
                  collects
                </li>
                <li>
                  Beiersdorf’s business/commercial purpose for collecting or
                  selling your Personal Information
                </li>
                <li>
                  Categories of third parties with whom Beiersdorf shares your
                  Personal Information
                </li>
                <li>Specific pieces of your Personal Information collected</li>
              </ul>
              <p className="font-semi-bold"><em>Right to Delete</em></p>
              <p>
                You have the right to request the deletion of your Personal
                Information that Beiersdorf has collected. This right, however,
                does not apply if Beiersdorf has a business need to retain your
                Personal Information to perform any of the following actions:
              </p>
              <ul>
                <li>Provide goods or services to you</li>
                <li>
                  Detect or resolve security issues or functionality-related
                  issues
                </li>
                <li>Comply with the law or legal obligation</li>
                <li>
                  Protect against malicious, deceptive, fraudulent activity
                </li>
                <li>Conduct research in the public interest</li>
                <li>Safeguard the right to free speech</li>
                <li>
                  Carry out any actions for internal purposes you may reasonably
                  expect Beiersdorf to carry out
                </li>
              </ul>
              <p className="font-semi-bold"><em>Data Portability and Beiersdorf Response</em></p>
              <p>
                Upon receiving your request to access and verifying your
                request, Beiersdorf will do our best to provide the necessary
                information collected in the immediately preceding 12 months to
                you within 45 days of Beiersdorf’s receipt of such request.
              </p>
              <p>
                If we require additional time (up to 90 days), Beiersdorf will
                inform you of the extension and the reason for such extension in
                writing.
              </p>
              <p>
                If Beiersdorf is unable to respond to your request, we will
                provide a reason as to why we cannot comply with your request.
              </p>
              <p className="font-semi-bold"><em>Non-Discrimination</em></p>
              <p>
                Beiersdorf will not discriminate against you for exercising your
                rights under the CCPA. We will not:
              </p>
              <ul>
                <li>Deny you goods or services</li>
                <li>Charge different prices or rates for goods or services</li>
                <li>Impose penalties</li>
                <li>Provide different level or quality of goods or services</li>
                <li>
                  Suggest you may receive a different price, rate, and/or
                  quality of goods or services
                </li>
              </ul>
              <p className="font-semi-bold"><em>Exercising Access, Data Portability, and Deletion Rights</em></p>
              <p>
                To exercise your rights to access, data portability, and
                deletion rights described directly above, please submit a
                consumer request that can be verifiable to Beiersdorf by:
              </p>
              <p>
                <span className="font-semi-bold">Call:</span> 1-800-227-4703
              </p>
              <p>
                <span className="font-semi-bold">Email:</span>{" "}
                <a
                  href="mailto:consumerrelations@beiersdorf.com"
                  className="text-link"
                >
                  consumerrelations@beiersdorf.com
                </a>
              </p>
              <p>
                <span className="font-semi-bold">Mail:</span> Consumer Relations
              </p>
              <p className="address">
                Beiersdorf Inc.
                <br />
                301 Tresser Blvd, 15th Floor
                <br />
                Stamford, CT 06902
              </p>
              <p>A verifiable consumer request must consist of:</p>
              <ul>
                <li>
                  Sufficient information that allows Beiersdorf to reasonably
                  verify you are the person whom we collected Personal
                  Information about, or an authorized representative
                </li>
                <li>
                  Sufficient detail that will allow Beiersdorf to properly
                  understand, evaluate, and respond to your request
                </li>
              </ul>
              <p>
                Only you, or a representative registered with the California
                Secretary of State that you authorize to act on your behalf, may
                make a verifiable consumer request related to your Personal
                Information. You may also make a verifiable consumer request on
                behalf of your minor child.
              </p>
              <p>
                You may only make a verifiable consumer request for access or
                data portability two times within a twelve month period.
              </p>
              <p>
                Beiersdorf cannot respond to your request or provide you with
                Personal Information if we cannot verify your identity or
                authority to make such request regarding Personal Information as
                it relates to you.
              </p>
              <p>
                Any Personal Information we may collect to verify a consumer
                request will only be used to verify the requesting party’s
                identity or authority to make such request.
              </p>
              <h2>9. Personal Information Collection of Children.</h2>
              <p>
                This website is designed by Beiersdorf and intended to be
                considered as a general audience website and is not intended for
                use by children. We do not knowingly collect, use or disclose
                Personal Information about visitors under 16 years of age.
              </p>
              <h2>10. Data Privacy Statement for Google Analytics.</h2>
              <p>
                This website uses Google Analytics, a web analytics service
                provided by Google, Inc. ("Google"). Google Analytics uses
                "cookies,” which are text files placed on your computer, to help
                the website analyze how users use the site. The information
                generated by the cookie about your use of the website (including
                your anonymized IP address) will be transmitted to and stored by
                Google on servers in the United States. Google will use this
                information for the purpose of evaluating your use of the
                website, compiling reports on website activity for website
                operators and providing other services relating to website
                activity and internet usage. Google may also transfer this
                information to third parties where required to do so by law, or
                where such third parties process the information on Google's
                behalf. Google will not associate your IP address with any other
                data held by Google. You may refuse the use of cookies by
                selecting the appropriate settings on your browser, however
                please note that if you do this you may not be able to use the
                full functionality of this website. By using this website, you
                consent to the processing of data about you by Google in the
                manner and for the purposes set out above.
              </p>
              <p>
                You can prevent Google from detecting a cookie that is generated
                because of and related to your usage of this website (including
                your IP address) as well as processing of these data by
                downloading and installing this browser plugin:{" "}
                <a
                  href="http://tools.google.com/dlpage/gaoptout?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  http://tools.google.com/dlpage/gaoptout?hl=en
                </a>
              </p>
              <p>This privacy policy was last updated September 2022.</p>
            </Col>
          </Row>
        </section>
      </div>
    </Layout>
  );
}

export default PrivacyPolicyPage;
